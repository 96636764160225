
import { useStore } from '@/store'
import { AgentType, IndexMessage } from '@/model/orderModel'
import { computed, defineComponent, onBeforeMount, ComputedRef, reactive, toRefs } from 'vue'
import DashCard from './components/DashCard.vue'
import { requestIndexMessage } from '@/apis/order'
import requestMiddle, { RequestInter } from '@/utils/request'
import ProfileHead from '@/components/profile-head/Index.vue'
import { UserMutationTypes } from '@/store/modules/user/mutation-types'
import { ElMessage } from 'element-plus'
import { AGENTSTATUS } from '@/store/modules/user/state'
import { useRouter } from 'vue-router'

interface DashboardDataInter{
  loading: boolean
  secondManage: number
  indexMessage: IndexMessage<any>
  getIndexMessage: () => void
}

export default defineComponent({
  components: {
    DashCard,
    ProfileHead
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const data: DashboardDataInter = reactive({
      secondManage: 0,
      loading: true,
      indexMessage: {},
      getIndexMessage: async() => {
        const requestData = await requestMiddle({
          requestMethodName: requestIndexMessage
        } as RequestInter)
        try {
          data.indexMessage = requestData
          store.commit(UserMutationTypes.SET_NOWDAY, requestData.now_day)
        } catch (error) {
          console.log(error)
        }
        data.loading = false
      }
    })

    const methods = reactive({
      toWithdrawal: () => {
        if ((data.indexMessage.commission.frozen_amount as number) > 0) {
          ElMessage({
            type: 'warning',
            message: '您有提现订单还未完结，完结后，在下一个提现周期可以进行提现。',
            offset: 300
          })
        } else if (data.indexMessage.agent.agent_status === AGENTSTATUS.FREEZE) {
          ElMessage({
            type: 'warning',
            message: '您的账号已经被冻结',
            offset: 300
          })
        } else if (Number(data.indexMessage.now_day) <= 5) {
          ElMessage({
            type: 'warning',
            message: '订单在结算中，请在5号后申请提现。',
            offset: 300
          })
        } else {
          router.push('/finace/withdraw')
        }
      }
    })

    const isdivider: ComputedRef<boolean> = computed(() => {
      return store.state.user.userinfo.agent_type === AgentType.DIVIDER
    })

    onBeforeMount(() => {
      data.getIndexMessage()
    })

    return {
      ...toRefs(data),
      ...toRefs(methods),
      isdivider
    }
  }
})
