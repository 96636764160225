<template>
  <div class="dash-card">
    <div class="left">
      <svg
        class="icon"
        aria-hidden="true"
        font-size="50px"
        :style="{'color': cardColor}"
      >
        <use :xlink:href="iconPath" />
      </svg>
      <div class="title-wrap">
        <p class="title">
          {{ title }}
        </p>
        <Tips :tipText="tipText" />
      </div>
      <h2
        :style="{'color': cardColor}"
        @click="handlePathTo"
      >
        <CountTo
          ref="myCount"
          :start-val="0"
          :end-val="counts"
          :duration="2600"
          class="card-panel-num"
          :decimals="decimals"
          :style="{'cursor': routerTo ? 'pointer' : 'default'}"
        />
      </h2>
    </div>

    <ul
      class="right"
      v-if="cardtype===2"
    >
      <li>已提现：{{ withdrawal }}</li>
      <li>可提现：{{ active }}</li>
      <li>
        <button
          class="green-border-btn"
          to="/icon/index"
          @click="$emit('btnHandle')"
        >
          申请提现
        </button>
      </li>
    </ul>
    <ul
      class="right"
      v-else
    >
      <li>昨天：{{ yesterdayCount }}</li>
      <li>最近7天：{{ sevenCount }}</li>
      <li>最近30天：{{ monthCount }}</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { CountTo } from 'vue3-count-to'
import Tips from '@/components/tips/Index.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    CountTo,
    Tips
  },
  name: 'DashCard',
  props: {
    counts: {
      type: Number,
      default: 0
    },
    yesterdayCount: {
      type: Number,
      default: 7
    },
    sevenCount: {
      type: Number,
      default: 60
    },
    monthCount: {
      type: Number,
      default: 223
    },
    tipText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    cardtype: {
      type: Number,
      default: 1
    },
    withdrawal: {
      type: String,
      default: ''
    },
    active: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: '#iconmessage'
    },
    cardColor: {
      type: String,
      default: '#59A2F1'
    },
    routerTo: {
      type: String,
      default: ''
    },
    decimals: {
      type: Number,
      default: 0
    }
  },
  emits: ['btnHandle'],
  setup(props) {
    const router = useRouter()
    const cardData = reactive({
      isTipShow: false,
      handlePathTo: () => {
        props.routerTo && router.push(props.routerTo)
      },
      toWithdrawal: () => {
        router.push('/finace/withdraw')
      }
    })
    return {
      ...toRefs(cardData)
    }
  }
})
</script>

<style lang="scss" scoped>
.dash-card {
  width: 32%;
  height: auto;
  background:#fff;
  display: flex;
  padding: 30px 50px;
  margin:0 2% 20px 0 ;
  min-width: 438px;
  .left {
    position: relative;
    padding-right: 30px;
    text-align: center;
    h2{
      // text-align: left;
      font-weight: 500;
      font-size: 32px;
    }
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 90%;
      position: absolute;
      right: 0;
      background: #ddd;
      top: 0;
      bottom: 0;
    }
    .title-wrap{
      display: flex;
      align-items: center;
    }
    .title{
      color: $subtitle;
      margin:18px 0 15px;
    }
    .tooltip{
      float: right;
      margin-left: 5px;
      position: relative;
      i{
        cursor: pointer;
        color: #707070;
        &:hover{
          color: $main;
        }
      }
      .text{
        position: absolute;
        padding: 10px 24px;
        background: #fff;
        color: #666;
        border-radius: 5px;
        font-size: 12px;
        margin: 0;
        width: 210px;
        z-index: 9;
        left: 10px;
        box-shadow: 0px 4px 10px 0px rgb(150 150 150 / 30%);
        border-radius: 11px;
        transform: translate(15px,-50%);
        line-height: 1.4;
        top: 5px;
        &::before, &::after{
          content: "";
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          transform: rotate(45deg) translateY(-50%);
          left: -8px;
          top: 50%;
          background: #fff;
          box-shadow: 0px 4px 10px 0px rgb(150 150 150 / 30%);
        }
        &::after{
          box-shadow:none;
          left: -5px;
        }
      }
    }
  }
  .right {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    li{
      color: #999;
      &:not(:first-child){
        margin-top:20px;
      }
    }
  }
  @mixin colorBtn($color) {
    background: $color;
    &:hover {
      color: $color;
      &:before,
      &:after {
        background: $color;
      }
    }
  }
  .light-blue-btn {
    @include colorBtn($light-blue);
    margin-top:10px;
  }
  .pan-btn {
    font-size: 14px;
    color: #fff;
    padding: 14px 36px;
    border-radius: 8px;
    border: none;
    outline: none;
    transition: 600ms ease all;
    position: relative;
    display: inline-block;
    &:hover {
      background: #fff;
      &:before,
      &:after {
        width: 100%;
        transition: 600ms ease all;
      }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    transition: 400ms ease all;
  }
  &::after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
  }
}
}
.dash-card:nth-of-type(3n){
  margin-right:0;
}
@media (max-width: 1024px){
  .dash-card{
    padding:30px;
    min-width: auto;
    width: 48.8%;
  }
  .dash-card:nth-of-type(2n){
    margin-right:0;
  }
}
@media (max-width: 768px){
  .dash-card:nth-of-type(3){
    width: auto;
  }
}
@media (max-width: 767px){
  .dash-card{
    padding:20px;
    .right{
      padding-left:20px;
    }
    .left{
      padding-right: 20px;
      .icon{
        font-size: 30px;
      }
      h2{
        font-size: 20px;
      }
      .title{
        font-size: 14px;
      }
      ::v-deep(.tooltip){
        .text{
          transform: translate( -50%,15px);
          &::after,&::before{
            display: none;
          }
        }
      }
    }
    .green-border-btn{
      padding:0 10px;
      font-size: 14px;
    }
  }
}
</style>
