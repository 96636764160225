<template>
  <div class="dashboard-head">
    <div class="left">
      <div class="avater-img">
        <img
          :src="avaterUrl"
          alt="用户头像"
        >
        <p
          v-if="isFreeze"
          class="freeze"
        >
          冻结
        </p>
      </div>
      <div class="info">
        <h1
          @click="handleToProfile"
          :style="{'cursor': isProfile ? 'default' : 'pointer'}"
        >
          你好，{{ agentinfo.agent_name }}
        </h1>
        <p>
          <img
            src="@/assets/images/dashboard/icon_1.png"
            alt="icon"
          >
          商户号：{{ agentinfo.business_code }}
        </p>
        <p>
          <img
            src="@/assets/images/dashboard/icon_2.png"
            alt="icon"
          >
          开户日期：<span>{{ createTime }}</span>
        </p>
        <p>
          <img
            src="@/assets/images/dashboard/icon_3.png"
            alt="icon"
          >
          代理级别：
          <span>M{{ agentinfo.agent_level }}</span>
          <span v-if="agentType===1">
            （{{ agentinfo.fixed_level === 0 ? '浮动': '固定' }}）
          </span>
        </p>
      </div>
    </div>
    <div
      class="right"
      v-if="agentinfo.fixed_level === 0 && hasProgress && !mobile"
    >
      <div class="slide-marks">
        <div class="slide-text">
          <span class="m-t">M1</span>
        </div>
        <div
          class="slide-text"
          :style="{'left': progressObj.secondManage + '%'}"
        >
          <span class="m-t">M2</span>
          <span class="m-m">{{ progressObj.secondPrice }}w</span>
        </div>
        <div
          class="slide-text"
          style="left:100%"
        >
          <span class="m-t">M3</span>
          <span class="m-m">{{ progressObj.thirdPrice }}w</span>
        </div>
      </div>
      <!-- <el-slider
        v-model="progressObj.progress"
        disabled
        :marks="progressObj.marks"
      /> -->
      <el-progress
        :text-inside="true"
        :stroke-width="30"
        :percentage="progressObj.progress"
        :format="format"
        :color="customColorMethod"
        :class="`${(progressObj.progress < 15) ? 'progreshort': 'progrelong'}`"
      >
        {{ agentinfo.month_amount }}
      </el-progress>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, toRefs, computed } from 'vue'
import { useStore } from '@/store'
import { useRouter, useRoute } from 'vue-router'
import defaultavatar from '@/assets/images/avatar.png'
import { IndexMessage } from '@/model/orderModel'
import { parseTime } from '@/utils'
import { DeviceType } from '@/store/modules/app/state'
import { AGENTSTATUS } from '@/store/modules/user/state'
const store = useStore()

export default defineComponent({
  props: {
    agentinfo: {
      type: Object,
      required: true
    },
    hasProgress: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const data = reactive({
      avaterUrl: defaultavatar
    })
    const isProfile = computed(() => {
      return /profile/.test(route.fullPath)
    })
    const methods = reactive({
      handleToProfile: () => {
        !isProfile.value && router.push('/profile/index')
      }
    })
    const agentType = computed(() => {
      return store.state.user.userinfo.agent_type
    })

    const isFreeze = computed(() => {
      return props.agentinfo.agent_status === AGENTSTATUS.FREEZE
    })

    const createTime = computed(() => {
      return parseTime(props.agentinfo.create_time, '{y}-{m}-{d}')
    })

    const progressObj = computed(() => {
      const obj: IndexMessage<string | number | object> | boolean = {}
      const { agentinfo, hasProgress } = props
      if (!hasProgress) return false
      obj.secondManage = (parseInt(agentinfo.first_stage as string) / parseInt(agentinfo.third_stage as string)) * 100
      obj.marks = {
        0: 'M1'
      }
      obj.marks[obj.secondManage] = 'M2'
      obj.marks[100] = 'M3'
      obj.progress = (agentinfo.month_amount as number * 100 / (parseInt(agentinfo.third_stage as string) * 100) * 100)
      obj.secondPrice = parseInt(agentinfo.first_stage as string) / 10000
      obj.thirdPrice = parseInt(agentinfo.third_stage as string) / 10000
      return obj
    })
    const progressMethods = reactive({
      format: () => {
        return props.agentinfo.month_amount
      },
      customColorMethod(percentage: number) {
        if (percentage < 30) {
          return '#909399'
        } else if (percentage < 70) {
          return '#e6a23c'
        } else {
          return '#67c23a'
        }
      }
    })
    const mobile = computed(() => {
      return store.state.app.device === DeviceType.Mobile
    })

    return {
      ...toRefs(data),
      agentType,
      progressObj,
      isProfile,
      ...toRefs(methods),
      createTime,
      mobile,
      isFreeze,
      ...toRefs(progressMethods)
    }
  }
})
</script>

<style scoped lang="scss">
  $barHeight:30px;
  .dashboard-head{
    display: flex;
    align-items: center;
    background: #fff;
    min-height: 200px;
    padding: 30px 55px;
    .left{
      display: flex;
      align-items: center;
      .avater-img{
        margin-right: 42px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
      }
      .freeze{
          position: absolute;
          background: rgba($color: #000, $alpha: 0.7);
          width: 100%;
          text-align: center;
          bottom: 0;
          color: #fff;
          font-size: 14px;
          padding: 5px;
        }
      .info{
        font-weight: 400;
        h1{
          color: #333;
          font-size: 22px;
          font-weight: 400;
          margin:0;
        }
        p{
          font-size: 18px;
          color: #666;
          margin-top: 20px;
          line-height: 1;
          display: flex;
          align-items: center;
          img{
            margin-right: 10px;
          }
        }
      }
    }
    .right{
      // width: 50%;
      flex:1;
      margin-left: 10%;
      position: relative;
      padding: 40px 0;
      max-width: 785px;
      .slide-marks{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        .slide-text{
          position: absolute;
          height: 100%;
          .m-t{
            width: 40px;
            height: 45px;
            line-height: 45px;
            box-shadow: 0px 4px 10px 0px rgb(150 150 150 / 30%);
            border-radius: 11px;
            background: #fff;
            display: block;
            position: absolute;
            font-size: 18px;
            color: #666;
            bottom: 0;
            transform: translateX(-50%);
            text-align: center;
            &::before{
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              transform: rotate(45deg) translateX(-50%);
              position: absolute;
              background: #fff;
              left: 50%;
              top: 0px;
            }
          }
          .m-m{
            font-size: 22px;
            color: #666;
            transform: translate(-50%, 50%);
            position: absolute;
          }
        }
      }
      ::v-deep(.el-progress){
        padding: 20px 0;
        &.progreshort{
          .el-progress-bar{
            .el-progress-bar__innerText{
              font-size: 16px;
              padding:0 10px;
            }
          }
          .el-progress-bar__inner{
            background-color: #51AA8D !important;
          }
        }
        &.progrelong .el-progress-bar{
          .el-progress-bar__innerText{
            font-size: 16px;
            position: absolute;
            height: 100%;
            right: 10px;
            line-height: 30px;
            z-index: 3;
          }
          .el-progress-bar__inner{
            overflow: hidden;
            &::after{
              content:"";
              display: block;
              width: 785px;
              height: 30px;
              background: linear-gradient(90deg, #000000 0%, #51AA8D 0%, #FFC453 98%);
              position: absolute;
              top: 0;
            }
          }
        }
      }
    }
  }
@media (max-width: 1200px){
  .dashboard-head{
    flex-wrap: wrap;
    .left, .right{
      width: 100%;
    }
    .right{
      margin:30px;
    }
  }
}

@media (max-width: 767px){
  .dashboard-head{
    padding:30px;
    .left{
    flex-direction: column;
    .avater-img{
      margin: 0 0 30px 0;
    }
    .info{
      h1{
        font-size: 18px;
        line-height: 1.4;
      }
      p{
        font-size: 14px;
        margin-top:10px;
      }
    }
  }
  }
}
</style>
