<template>
  <div
    class="tooltip"
    v-if="tipText"
  >
    <i
      class="el-icon-question tipicon"
      @mouseover.stop="isTipShow=true"
      @mouseout.stop="isTipShow=false"
    />
    <p
      class="text"
      v-show="isTipShow"
    >
      {{ tipText }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  name: 'Tips',
  props: {
    tipText: {
      type: String,
      default: ''
    }
  },
  setup() {
    const cardData = reactive({
      isTipShow: false
    })

    return {
      ...toRefs(cardData)
    }
  }
})
</script>

<style lang="scss" scoped>
.tooltip{
  float: right;
  margin-left: 5px;
  position: relative;
  i{
    cursor: pointer;
    color: #707070;
    &:hover{
      color: $main;
    }
  }
  .text{
    position: absolute;
    padding: 10px 24px;
    background: #fff;
    color: #666;
    border-radius: 5px;
    font-size: 12px;
    margin: 0;
    width: 210px;
    z-index: 9;
    left: 10px;
    box-shadow: 0px 4px 10px 0px rgb(150 150 150 / 30%);
    border-radius: 11px;
    transform: translate(15px,-50%);
    line-height: 1.4;
    top: 5px;
    &::before, &::after{
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      transform: rotate(45deg) translateY(-50%);
      left: -8px;
      top: 50%;
      background: #fff;
      box-shadow: 0px 4px 10px 0px rgb(150 150 150 / 30%);
    }
    &::after{
      box-shadow:none;
      left: -5px;
    }
  }
}
</style>
