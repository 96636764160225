
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  name: 'Tips',
  props: {
    tipText: {
      type: String,
      default: ''
    }
  },
  setup() {
    const cardData = reactive({
      isTipShow: false
    })

    return {
      ...toRefs(cardData)
    }
  }
})
