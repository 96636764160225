
import { defineComponent, reactive, toRefs, computed } from 'vue'
import { useStore } from '@/store'
import { useRouter, useRoute } from 'vue-router'
import defaultavatar from '@/assets/images/avatar.png'
import { IndexMessage } from '@/model/orderModel'
import { parseTime } from '@/utils'
import { DeviceType } from '@/store/modules/app/state'
import { AGENTSTATUS } from '@/store/modules/user/state'
const store = useStore()

export default defineComponent({
  props: {
    agentinfo: {
      type: Object,
      required: true
    },
    hasProgress: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const data = reactive({
      avaterUrl: defaultavatar
    })
    const isProfile = computed(() => {
      return /profile/.test(route.fullPath)
    })
    const methods = reactive({
      handleToProfile: () => {
        !isProfile.value && router.push('/profile/index')
      }
    })
    const agentType = computed(() => {
      return store.state.user.userinfo.agent_type
    })

    const isFreeze = computed(() => {
      return props.agentinfo.agent_status === AGENTSTATUS.FREEZE
    })

    const createTime = computed(() => {
      return parseTime(props.agentinfo.create_time, '{y}-{m}-{d}')
    })

    const progressObj = computed(() => {
      const obj: IndexMessage<string | number | object> | boolean = {}
      const { agentinfo, hasProgress } = props
      if (!hasProgress) return false
      obj.secondManage = (parseInt(agentinfo.first_stage as string) / parseInt(agentinfo.third_stage as string)) * 100
      obj.marks = {
        0: 'M1'
      }
      obj.marks[obj.secondManage] = 'M2'
      obj.marks[100] = 'M3'
      obj.progress = (agentinfo.month_amount as number * 100 / (parseInt(agentinfo.third_stage as string) * 100) * 100)
      obj.secondPrice = parseInt(agentinfo.first_stage as string) / 10000
      obj.thirdPrice = parseInt(agentinfo.third_stage as string) / 10000
      return obj
    })
    const progressMethods = reactive({
      format: () => {
        return props.agentinfo.month_amount
      },
      customColorMethod(percentage: number) {
        if (percentage < 30) {
          return '#909399'
        } else if (percentage < 70) {
          return '#e6a23c'
        } else {
          return '#67c23a'
        }
      }
    })
    const mobile = computed(() => {
      return store.state.app.device === DeviceType.Mobile
    })

    return {
      ...toRefs(data),
      agentType,
      progressObj,
      isProfile,
      ...toRefs(methods),
      createTime,
      mobile,
      isFreeze,
      ...toRefs(progressMethods)
    }
  }
})
