
import { defineComponent, reactive, toRefs } from 'vue'
import { CountTo } from 'vue3-count-to'
import Tips from '@/components/tips/Index.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    CountTo,
    Tips
  },
  name: 'DashCard',
  props: {
    counts: {
      type: Number,
      default: 0
    },
    yesterdayCount: {
      type: Number,
      default: 7
    },
    sevenCount: {
      type: Number,
      default: 60
    },
    monthCount: {
      type: Number,
      default: 223
    },
    tipText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    cardtype: {
      type: Number,
      default: 1
    },
    withdrawal: {
      type: String,
      default: ''
    },
    active: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: '#iconmessage'
    },
    cardColor: {
      type: String,
      default: '#59A2F1'
    },
    routerTo: {
      type: String,
      default: ''
    },
    decimals: {
      type: Number,
      default: 0
    }
  },
  emits: ['btnHandle'],
  setup(props) {
    const router = useRouter()
    const cardData = reactive({
      isTipShow: false,
      handlePathTo: () => {
        props.routerTo && router.push(props.routerTo)
      },
      toWithdrawal: () => {
        router.push('/finace/withdraw')
      }
    })
    return {
      ...toRefs(cardData)
    }
  }
})
