<template>
  <div
    class="dashboard-container"
  >
    <template v-if="!loading">
      <ProfileHead
        :agentinfo="indexMessage.agent"
      />
      <div class="card-list">
        <DashCard
          :counts="indexMessage.transaction?.all || 0"
          :yesterdayCount="indexMessage.transaction.yesterday"
          :sevenCount="indexMessage.transaction.seven_day"
          :monthCount="indexMessage.transaction.month_day"
          :title="'总订单数'"
          :routerTo="'/order'"
        />
        <DashCard
          :counts="indexMessage.user?.all || 0"
          :yesterdayCount="indexMessage.user.yesterday"
          :sevenCount="indexMessage.user.seven_day"
          :monthCount="indexMessage.user.month_day"
          :title="'总用户数'"
          :tipText="'领取了福利的新用户数量'"
          :iconPath="'#iconpeoples'"
          :cardColor="'#6FC6C5'"
          v-if="isdivider"
        />
        <DashCard
          :tipText="'如代理级别发生变化，本月数据将在下月1号重新计算。'"
          :title="'我的佣金（元）'"
          :counts="indexMessage.commission?.all || 0"
          :withdrawal="indexMessage.commission.has_withdrawal"
          :active="indexMessage.commission.active_amount"
          :cardtype="2"
          :iconPath="'#iconrmb'"
          :cardColor="'#DE5D6F'"
          :routerTo="'/order'"
          :decimals="2"
          @btnHandle="toWithdrawal"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { useStore } from '@/store'
import { AgentType, IndexMessage } from '@/model/orderModel'
import { computed, defineComponent, onBeforeMount, ComputedRef, reactive, toRefs } from 'vue'
import DashCard from './components/DashCard.vue'
import { requestIndexMessage } from '@/apis/order'
import requestMiddle, { RequestInter } from '@/utils/request'
import ProfileHead from '@/components/profile-head/Index.vue'
import { UserMutationTypes } from '@/store/modules/user/mutation-types'
import { ElMessage } from 'element-plus'
import { AGENTSTATUS } from '@/store/modules/user/state'
import { useRouter } from 'vue-router'

interface DashboardDataInter{
  loading: boolean
  secondManage: number
  indexMessage: IndexMessage<any>
  getIndexMessage: () => void
}

export default defineComponent({
  components: {
    DashCard,
    ProfileHead
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const data: DashboardDataInter = reactive({
      secondManage: 0,
      loading: true,
      indexMessage: {},
      getIndexMessage: async() => {
        const requestData = await requestMiddle({
          requestMethodName: requestIndexMessage
        } as RequestInter)
        try {
          data.indexMessage = requestData
          store.commit(UserMutationTypes.SET_NOWDAY, requestData.now_day)
        } catch (error) {
          console.log(error)
        }
        data.loading = false
      }
    })

    const methods = reactive({
      toWithdrawal: () => {
        if ((data.indexMessage.commission.frozen_amount as number) > 0) {
          ElMessage({
            type: 'warning',
            message: '您有提现订单还未完结，完结后，在下一个提现周期可以进行提现。',
            offset: 300
          })
        } else if (data.indexMessage.agent.agent_status === AGENTSTATUS.FREEZE) {
          ElMessage({
            type: 'warning',
            message: '您的账号已经被冻结',
            offset: 300
          })
        } else if (Number(data.indexMessage.now_day) <= 5) {
          ElMessage({
            type: 'warning',
            message: '订单在结算中，请在5号后申请提现。',
            offset: 300
          })
        } else {
          router.push('/finace/withdraw')
        }
      }
    })

    const isdivider: ComputedRef<boolean> = computed(() => {
      return store.state.user.userinfo.agent_type === AgentType.DIVIDER
    })

    onBeforeMount(() => {
      data.getIndexMessage()
    })

    return {
      ...toRefs(data),
      ...toRefs(methods),
      isdivider
    }
  }
})
</script>
<style lang="scss" scoped>
.dashboard-container{
  border:1px solid #eee;
  min-height: calc(100vh - 150px)
}

.card-list{
  display: flex;
  // justify-content: space-between;
  margin:30px 0;
  flex-wrap: wrap;
}
@media (max-width: 767px){
  .card-list{
    flex-direction: column;
    ::v-deep(.dash-card){
      width: 100%;
    }
  }
}
</style>
